<template>
    <div>
        <!-- <b-sidebar style="top:56px !important; width:250px !important;" visible id="sidebar-1" title="Recommendations" no-header shadow> -->
        <b-sidebar class="p-3" style="top:56px !important; width:250px !important;" id="sidebar-1" title="Feather" shadow>

        <b-button class="display-inline-block mb-3" variant="primary"  v-b-modal.record-activity-modal>Record Activity</b-button>
        <b-button class="display-inline-block float-right" variant="light" v-b-modal.days-chart-modal>View Chart</b-button>
        <user-stats />

        </b-sidebar>
    </div>
</template>



<script>
import UserStats from './UserStats.vue'

export default {
  name: 'Sidebar',
  components: {
    UserStats
  },
//   props: {
//     msg: String
//   }
  props: [""],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.b-sidebar {
  width: 250px !important;
  top:56px !important;
}

</style>

