<template>
  <div>
    <b-card class="activities-overview box-shadow">
        <b-card-title v-if="!hideTitle">{{ getTitle()}}</b-card-title>
        <p> {{ startDate }} - {{ endDate }} </p>

        <div class="mb-3 text-left p-3" v-if="days">
            <!-- <b-card-title>other notes..</b-card-title> -->
            <b-card-text>
              <div v-if="days.Great.count > 0"
                class="days-overview-card box-shadow p-2 m-2 text-center"
              >
                <span class="big-font">{{ days.Great.count }}</span> GREAT days!
              </div>

              <div v-if="days.Good.count > 0"
                class="days-overview-card box-shadow p-2 m-2 text-center"
              >
                <span class="big-font">{{ days.Good.count }}</span> Good days!
              </div>

              <div v-if="days.Ok.count > 0"
                class="days-overview-card-ok box-shadow p-2 m-2 text-center"
              >
                <span class="big-font">{{ days.Ok.count }}</span> Ok days...
              </div>

              <div v-if="days.Meh.count > 0"
                class="days-overview-card-meh box-shadow p-2 m-2 text-center"
              >
                <span class="big-font">{{ days.Meh.count }}</span> meh days
              </div>

              <div v-if="days.Bad.count > 0"
                class="days-overview-card-bad box-shadow p-2 m-2 text-center"
              >
                <span class="big-font">{{ days.Bad.count }}</span> bad days... =(
                </div>

            </b-card-text>
        </div>
        
        <!-- <span>x per week</span> -->
        <b-card-text v-if="occurrences && splitView" style="text-align:left;">
          <b-row>
            <b-col cols="6">
              <div v-for="defaultActivity in occurrences1" v-bind:key="defaultActivity.id" style="cursor:pointer;">
                <span class="activity-title">{{ defaultActivity.title }}</span>
                <div style="display:inline-block;height:27px;width:200px; position:relative;">
                    <span v-if="defaultActivity.value >= 0" class="good-bar" :style='"display:inline-block;height:100%;width:" + getPercentageWidth(defaultActivity.count) + "%"'></span>
                    <span v-if="defaultActivity.value < 0" class="bad-bar" :style='"display:inline-block;background:#ff4816;height:100%;width:" + getPercentageWidth(defaultActivity.count) + "%"'></span>
                <span class="ml-1" style="position:absolute;left:4px;top:1px;color:white;">{{ defaultActivity.count }} </span>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div v-for="defaultActivity in occurrences2" v-bind:key="defaultActivity.id" style="cursor:pointer;">
                <span class="activity-title">{{ defaultActivity.title }}</span>
                <div style="display:inline-block;height:27px;width:200px; position:relative;">
                    <span v-if="defaultActivity.value >= 0" class="good-bar" :style='"display:inline-block;height:100%;width:" + getPercentageWidth(defaultActivity.count) + "%"'></span>
                    <span v-if="defaultActivity.value < 0" class="bad-bar" :style='"display:inline-block;background:#ff4816;height:100%;width:" + getPercentageWidth(defaultActivity.count) + "%"'></span>
                <span class="ml-1" style="position:absolute;left:4px;top:1px;color:white;">{{ defaultActivity.count }} </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-text>
        <b-card-text v-if="occurrences && !splitView" style="text-align:left;">
          <div v-for="defaultActivity in occurrences" v-bind:key="defaultActivity.id" style="cursor:pointer;">
            <span class="activity-title">{{ defaultActivity.title }}</span>
            <div style="display:inline-block;height:27px;width:200px; position:relative;">
                <span v-if="defaultActivity.value >= 0" class="good-bar" :style='"display:inline-block;height:100%;width:" + getPercentageWidth(defaultActivity.count) + "%"'></span>
                <span v-if="defaultActivity.value < 0" class="bad-bar" :style='"display:inline-block;background:#ff4816;height:100%;width:" + getPercentageWidth(defaultActivity.count) + "%"'></span>
            <span class="ml-1" style="position:absolute;left:4px;top:1px;color:white;">{{ defaultActivity.count }} </span>
            </div>
          </div>
        </b-card-text>
    </b-card>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'ActivitiesOverview',
  props: ['timeframe', 'reloadOverview', 'hideTitle', 'splitView'],
  data() {
    return {
        max: 0,
        occurrences: [],
        occurrences1: [],
        occurrences2: [],
        startDate: '',
        endDate: '',
        days: {},
        daysString : ''
    }
  },
  created() {
    this.loadData() 
  
  },
  watch: { 
    timeframe: {
      handler(val, oldVal) {
        console.log(val)
        console.log(oldVal)
        this.loadData()
      }
    }
  },
  methods: {
    loadData() {
      var url = `${process.env.VUE_APP_HOST}/api/get-activities-stats/`
      if (this.timeframe) {
          url += this.timeframe + '/'
      }
      axios.get(url)
      .then(response => {
        console.log('ayoresponse')
        console.log(response.data)
        this.days = response.data.days

        this.occurrences = response.data.occurrences
        const l = this.occurrences.length
        if (l != 0) {
          this.max = this.occurrences[0].count
          const h = parseInt(l / 2)
          this.occurrences1 = this.occurrences.slice(0,h)
          this.occurrences2 = this.occurrences.slice(h,l)
        } else {
          this.occurrences1 = this.occurrences
          this.occurrences2 = this.occurrences
        }
        this.startDate = response.data.start_date
        this.endDate = response.data.end_date
      })
      .catch(e => {
        this.errors.push(e)
      })           
    },
    getPercentageWidth (n) {
      const perc = ((n / this.max) * 100).toFixed(0)
      return perc
    }, 
    // reloadData() {
    //   console.log('activitiesdata reloading data..')
    // },
    getTitle() {
        if (this.timeframe == "week") {
            return "This Week's Breakdown"
        } else if (this.timeframe == "lastweek") {
            return "Last Week's Breakdown"
        } else if (this.timeframe == "month") {
            return "This Month's Breakdown"
        } else if (this.timeframe == "year") {
            return "This Year's Breakdown"
        } else if (this.timeframe == "alltime") {
            return "Activity Breakdown (All Time)"
        } 
        return "Breakdown"
        // return "Activity Breakdown (All Time)"
    }
  }  
}
</script>

<style lang="scss" scoped>

.activities-overview {
  font-family: 'Catamaran', sans-serif;
}

.activity {
  cursor: pointer;
  border:1px solid gray;
  padding: 8px;
  transition-duration: 0.75s;
  border-radius: 5px;

  &.is-selected {
    background: #33cc99 ;
    border: 1px solid #33cc99;
    color: white;
  }
}

.activity-title {
  width: 200px;
  display:inline-block;
  border-radius:10px;
}

.days-overview-card {
  border: 1px solid #33cc99;
  display: inline-block;
  color:white;
  // background:#33cc99;
  background: #35A185;
  min-height:100px;
  width:174px;
  font-size: 20px;
  border-radius:10px;
}

.days-overview-card-ok {
  border: 1px solid darkgray;
  display: inline-block;
  color: darkgray;
  background:white;
  min-height:100px;
  width:174px;
  font-size: 20px; 
  border-radius:10px;
}

.days-overview-card-meh {
  border: 1px solid lightgray;
  display: inline-block;
  color:white;
  background: lightgray;
  min-height:100px;
  width:174px;
  font-size: 20px; 
  border-radius:10px;
}


.days-overview-card-bad {
  border: 1px solid #ff4816;
  display: inline-block;
  color:white;
  background: #ff4816;
  min-height:100px;
  width:174px;
  font-size: 20px; 
}


.big-font {
  font-size: 48px;
}

.good-bar {
  border-radius:10px;
  background: #35A185;
}

.bad-bar {
  border-radius: 10px;
}


.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
}


</style>

