<template>
  <div>
    <b-card class="">
        <!-- <b-card-title class="mt-2">Your Stats!</b-card-title> -->
        <b-card-text class="">
          <p>You had {{ goodDaysCount }} good days total!</p>
          <div>you have the most productive days on tuesday</div>
          <div>you have your happiest days on friday, when you... xyz</div>
        </b-card-text>
    </b-card>




    </div>
</template>


<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'

export default {
  name: 'UserQuotes',
  props: [''],
  data() {
    return {
      csrfToken: '',
      userStats: {},
      goodDaysCount: 0,
      getUserStatsUrl: `${process.env.VUE_APP_HOST}/api/get-user-stats/`,
    }
  },
  computed: {

  },  
  created() {
    this.csrfToken = VueCookies.get('csrftoken')
    const postHeaders = { "content-type": "application/json", 'X-CSRFToken': this.csrfToken }
    // const url = `${process.env.VUE_APP_HOST}/api/get-current-info/`

    axios.get(this.getUserStatsUrl, postHeaders)
    .then(response => {
      console.log('user stats.. response.. quotes...')
      console.log(response)
      this.goodDaysCount = response.data.good_days_count
    })

  
  },
  methods: {


  }  
}
</script>

<style lang="scss" scoped>


</style>

