<template>
  <div>
    <!-- https://tiptap.dev/api/extensions/starter-kit -->
    <!-- https://tiptap.dev/api/marks -->
      <!-- tiptap starterkit doesn't come with underline.. apparently..womp.. -->
    <div style="border:1px solid #007bff;">
      <b-button variant="outline-primary" style="border:none;" :class="{ 'active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
        <b-icon-type-bold font-scale="1" aria-hidden="true"></b-icon-type-bold>
      </b-button>

      <b-button variant="outline-primary" style="border:none;" :class="{ 'active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
        <b-icon-type-italic font-scale="1" aria-hidden="true"></b-icon-type-italic>
      </b-button>

      <!-- <b-button variant="outline-primary" style="border:none;" :class="{ 'active': editor.isActive('strike') }" @click="editor.chain().focus().toggleStrike().run()">
        <b-icon-type-strikethrough font-scale="1" aria-hidden="true"></b-icon-type-strikethrough>
      </b-button>

      <b-button variant="outline-primary" style="border:none;" :class="{ 'active': editor.isActive('code') }" @click="editor.chain().focus().toggleCode().run()">
        <b-icon-code-slash font-scale="1" aria-hidden="true"></b-icon-code-slash>
      </b-button> -->

      <b-button variant="outline-primary" style="border:none;" :class="{ 'active': editor.isActive('heading', { level: 1 }) }" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
        <b-icon-type-h1 font-scale="1" aria-hidden="true"></b-icon-type-h1>
      </b-button>

      <b-button variant="outline-primary" style="border:none;" :class="{ 'active': editor.isActive('heading', { level: 2 }) }" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        <b-icon-type-h2 font-scale="1" aria-hidden="true"></b-icon-type-h2>
      </b-button>

      <b-button variant="outline-primary" style="border:none;" :class="{ 'active': editor.isActive('heading', { level: 3 }) }" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
        <b-icon-type-h3 font-scale="1" aria-hidden="true"></b-icon-type-h3>
      </b-button>


      <!-- <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        toggleBlockquote
      </button> -->

      <!-- <button @click="editor.chain().focus().setHorizontalRule().run()">
        setHorizontalRule
      </button> -->
    </div>
    
    <div 
      v-if="!hidePlaceholder"
      id="editor-content-placeholder"
      style="position:absolute;margin-top:10px;margin-left:10px;color:gray;"
    >add notes here..</div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    existingNoteText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
      hidePlaceholder: false,
    }
  },
  created () {
    if (this.existingNoteText) {
      this.value = this.existingNoteText
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // base html is <p></p> so its length is 7..
        if (this.editor.getHTML().length > 7 ) {
          this.hidePlaceholder = true
        } else {
          this.hidePlaceholder = false
        }
        // console.log(this.editor)
        // console.log(this.editor.getHTML().length)
        // console.log(this.editor.getHTML())
        // this.$emit('input', this.editor.getJSON())
        this.$emit('input', this.editor.getHTML())
      },
    })
    this.editor.setOptions({
      editorProps: {
        attributes: {
          class: 'wysiwyg-editor-content',
        }
      }
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>


<style lang="scss">

// .is-active {
//   background: #007bff;
//   color: white;
// }

.wysiwyg-editor-content {
  // min-height:200px;
  min-height:100px;
  padding: 8px;
  border:1px solid gray;
}


</style>