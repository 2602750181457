<template>
  <b-container>


    <div v-if="!editValuesMode">
      <div v-if="showSelectedDay && editMode">
        <b-row>
          <b-col cols="1" style="height:60px;">
            <span v-if="score !== 0" class="ml-3 score float-right">{{ score }}</span>
  
            <b-button v-if="showSelectedDay" id="edit-button" class="float-right" @click="toggleEdit()" variant="outline-primary">
              <b-icon-pencil-square v-if="editMode" font-scale="2" aria-hidden="true"></b-icon-pencil-square>
              <b-icon-x v-if="!editMode" font-scale="2" aria-hidden="true"></b-icon-x>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
            <b-col cols="11" class="text-center">
              <b-form-datepicker 
                v-if="!showProgressBar" 
                id="example-datepicker" 
                :disabled="disableDatepicker" 
                v-model="dateValue" 
                class="mb-2" 
                style="width:228px;"
              ></b-form-datepicker>
              <div >
                <div class="item mb-3" style="width: 250px;" v-for="bucket in buckets" v-bind:key="bucket.id">
                  <span v-if="bucket.activities.length > 0">
                    <span v-for="act in bucket.activities" v-bind:key="act.id" >
                      <div v-if="selectedIds.includes(act.id)" class="p-2 m-1" :class="[{ 'is-selected': selectedIds.includes(act.id) }, act.value < 0 ? 'bad-activity-select': 'activity-select']">
                        <span class="mr-2">{{ act.title }}</span>
                        <b-badge variant="light">{{ act.value }}</b-badge>
                      </div>
                    </span>    
                  </span>
                </div>
              </div>

            </b-col>
            <b-col cols="12">
              <p style="text-align:left;" class="py-2">
                <span class="day-note-text mr-2 ">Overall, today was..</span>
                <span id="day-rating-text">{{ getRating(dayRating) }}</span>
              </p>      
              <!-- // todo submit as html -->
              <p v-if="noteText" class="text-left day-note-text pt-3" style="border-top: 0.5px solid gray;" v-html="noteText"/>
            </b-col>  
        </b-row>
      </div>


      <div v-else>
        <!-- WHEN FILLING OUT THE FORM IN REGULAR MODE~!
         -->
        <b-progress v-if="showProgressBar" class="my-2" height="40px" :value="progressValue" :max="maxValue" show-progress></b-progress>
        <b-alert v-model="showAlert" variant="danger" dismissible>{{ alertMessage }}</b-alert>

        <div>
          <b-row>
            <b-col cols="3" class="">
              <b-form-datepicker 
                v-if="!showProgressBar" 
                :disabled="disableDatepicker" 
                id="example-datepicker" 
                v-model="dateValue" 
                class="mb-2 d-inline-block" 
                style="width:228px;"
              ></b-form-datepicker>
              <b-button 
                id="create-edit-activities-button" 
                class="mt-2"
                @click="visitEditor()" 
                variant="outline-primary"
              >
                <b-icon-pencil-square 
                  font-scale="1" 
                  aria-hidden="true"
                  class="mr-2"
                >
                </b-icon-pencil-square>Activities
              </b-button>                
            </b-col>
            <b-col cols="6" class="">
              <b-form-group
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  id="btn-radios-2"
                  class="mb-2 d-inline-block" 
                  v-model="dayRating"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  size="sm"
                  name="radio-btn-outline"
                  buttons
                  style="font-size:12px;"
                >
                </b-form-radio-group>
              </b-form-group>
              <div 
              v-if="!showNoteEditor" 
                class="my-2" 
                @click="displayNoteEditor()" 
                style="cursor:pointer;text-decoration: underline;color:blue;"
              >Add notes</div>
              <div v-if="showNoteEditor" class="my-3 text-left" style="text-align:left;">
                <tip-tap 
                  @input="setContents" 
                  style="text-align:left;" 
                  :existingNoteText="noteText"
                />
              </div>
              <div 
                v-if="showNoteEditor" 
                class="my-2" 
                @click="hideNoteEditor()" 
                style="cursor:pointer;text-decoration: underline;color:blue;"
              >hide notes</div>              
            </b-col>

            <b-col v-if="!editValuesMode" cols="3" style="height:60px;">
              <b-button 
                class="" 
                :disabled="disableSubmitButton" 
                variant="primary" 
                @click="submitDaysActivities()"
              >Save Entry</b-button>
  
              <div 
              >
              <!-- class="links display-block w-100 py-4" -->
                <div 
                  v-if="showSelectedDay" 
                  class="delete link float-right text-right"
                  @click="showDeleteEntryModal()"
                >Delete Entry</div>
              </div>

              <span 
                v-if="score !== 0" 
                class="score"
                style="position:absolute;top:0;"
              >{{ score }}</span>

              <b-button v-if="showSelectedDay" 
                id="edit-button" 
                class="float-right" 
                @click="toggleEdit()" 
                variant="outline-primary"
              >
                <b-icon-pencil-square 
                  v-if="editMode" 
                  font-scale="2" 
                  aria-hidden="true"
                ></b-icon-pencil-square>
                <b-icon-x 
                  v-if="!editMode" 
                  font-scale="2" 
                  aria-hidden="true"
                ></b-icon-x>
              </b-button>
            
            </b-col>

          </b-row>
          <b-row>
            <b-col cols="12" class="text-center">
              <!-- option 4 masonry -->
              <div class="">
                <div id="" style="width: 100%; min-height: 500px;">

                <!-- <div id="masonry-test" style="width: 100%; min-height: 500px;"> -->
                  <!-- <div v-masonry="masonry-test" transition-duration="0.3s" item-selector=".item"> -->
                    <!-- <div v-masonry-tile class="item mb-3" style="width: 250px;" v-for="bucket in buckets" v-bind:key="bucket.id"> -->
                    <div v-masonry="masonryId" transition-duration="0.3s" item-selector=".item" style="top:60px;">
                      <div v-masonry-tile="masonryId" class="item mb-3" style="width: 250px;" v-for="bucket in buckets" v-bind:key="bucket.id">

                      <span v-if="bucket.activities.length > 0">
                        <div style="font-size:18px;text-align:left;">{{ bucket.title }}</div>
                        <span v-for="act in bucket.activities" v-bind:key="act.id" @click="selectActivity(act)" class="p-2 m-1 selectable"  :class="[{ 'is-selected': selectedIds.includes(act.id) }, act.value < 0 ? 'bad-activity-select': 'activity-select']">
                          <span class="mr-2">{{ act.title }}</span>
                          <b-badge variant="light">{{ act.value }}</b-badge>
                        </span>    
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </b-col>
          </b-row>
        </div>
          <b-modal
            size="md"
            id="delete-entry-modal"
            title="Delete entry"
            @ok="confirmDeleteEntry()"
          >
            <p class="my-4">Are you sure you want to delete this entry? there's no going back...</p>
            <template #modal-footer="{ cancel, hide }">
              <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
                Cancel
              </b-button>

              <b-button size="sm" variant="danger" @click="confirmDeleteEntry()">
                Delete
              </b-button>
            </template>

          </b-modal>
      </div>
    </div>

    <div v-else>
      <!-- <div class="link" @click="editValuesMode = false">cancel</div> -->
      <b-button @click="loadBuckets()" variant="light"><b-icon-chevron-left />back to form</b-button>
      <bucket-editor />
    </div>


  </b-container>
</template>


<script>
import axios from 'axios'
import moment from 'moment'
import VueCookies from 'vue-cookies'
// import VueMasonryPlugin from 'vue-masonry';
// import EditActivitiesForm from './EditActivitiesForm'
import BucketEditor from './BucketEditor'
import TipTap from './TipTap.vue'

export default {
  name: 'RecordActivitiesForm',
  components: {
    BucketEditor,
    TipTap,
  },
  props: ['selectedDay', 'showSelectedDay'],
  data() {
    return {
      showNoteEditor: false,
      editMode: true,
      showProgressBar: false,
      progressValue: 0,
      maxValue: 100,

      masonryId: 'masonry-grid-1',
      
      csrfToken: '',
      deleteEntryUrl: `${process.env.VUE_APP_HOST}/api/delete_day_entry/`,
      submitActivityUrl: `${process.env.VUE_APP_HOST}/api/submit_activity/`,
      dateValue: new Date(),
      dayRating: 0,
      score: 0,
      disableSubmitButton: false,
      disableDatepicker: false,
      
      editValuesMode: false,
      buckets: [],
      selectedIds: [], 
      noteText: '', 
      options: [
        { text: 'a bad day', value: '1' },
        { text: 'a MEH day', value: '2' },
        { text: 'an OK day', value: '3' },
        { text: 'a good day', value: '4' },
        { text: 'a GREAT day!', value: '5' },
      ],        

      showAlert: false,
      alertMessage: 'Something went wrong.,.',
      // positiveActivities: [],
      // badActivities: [],
    }
  },
  computed: {

  },  
  created() {
    if (this.showSelectedDay) {
      this.disableDatepicker = true
      // this.disableSubmitButton = true
      this.dateValue = new Date(this.selectedDay.date.date)
      this.dayRating = this.selectedDay.rating
      this.score = this.selectedDay.score
      this.noteText = this.selectedDay.note
      console.log('selecteday...activities...')
      console.log(this.selectedDay.activities)
      this.selectedIds = this.selectedDay.activities.map((e) => {
        return e.dafault_activity_id
      })
      // -- when clicking on a day it shows the ActivityIDs, not Default Activity Ids - so we need to adjust that somehow...
    } 
    this.csrfToken = VueCookies.get('csrftoken')

    this.loadBuckets()
  
  },
  methods: {
    loadBuckets() {
      this.editValuesMode = false 
      this.$redrawVueMasonry('masonry-grid-1') // just setting the IDs on both, worked !! dont need this

      console.log('alrighty loading buckets..')
      const postHeaders = { "content-type": "application/json", 'X-CSRFToken': this.csrfToken }
      const url = `${process.env.VUE_APP_HOST}/api/get-current-info/`
      axios.get(url, postHeaders)
      .then(response => {
        this.buckets = response.data.activity_buckets
      })      
    },
    toggleEdit () {
      console.log('toggling edit..')
      if (this.editMode) {
        this.editMode = false
      } else {
        this.editMode = true
      }
    },
    // pythonPageDate(date) {
    //   var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    //   return date.toLocaleDateString("en-US", options)
    // },
    selectActivity(act) {
      // console.log(`selecting activity ${act.id}, ${act.title}`)
      if (this.selectedIds.includes(act.id)) {
        this.selectedIds = this.selectedIds.filter((id) => parseInt(id) !== parseInt(act.id))
        this.score -= act.value
      } else {
        this.selectedIds.push(act.id)
        this.score += act.value
      }
    },
    submitDaysActivities () {

      console.log('submitting days activites..')
      const m = moment(this.dateValue, 'YYYY-MM-DD')
      const formattedDate = moment(m, 'MM-DD-YYYY').format('MM/DD/YYYY')
      const payload = {
          date: formattedDate,
          default_activity_ids: this.selectedIds,
          score: parseInt(this.score),
          rating: parseInt(this.dayRating),
          note: this.noteText
      }
      if (this.showSelectedDay) {
        console.log('editing a selected day...')
        payload['edit_mode'] = true
        payload['date_id'] = this.selectedDay.id
      }

      // '05/08/2022' -- this works..
      axios.post(this.submitActivityUrl, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(response => {
          // this.$store.commit('setActiveUser', response.data)
          console.log(response.data)
          if (response.data.status == 200) {
            this.showProgressBar = true
            // setTimeout(() => {  this.progressValue = 100; location.href = '/dashboard'; }, 250);
            this.$emit('reload-data')
            console.log('get on out of your seat!')
            this.editMode = false
            // setTimeout(() => {  this.$bvModal.hide('record-activity-modal'); }, 1250);
            this.$bvModal.hide('record-activity-modal')


            // TODO !!!! ~~~ RE-LOAD THE WEEK'S BREAKDOWN POST..
          } else if (response.data.status == 400) {
            this.showAlert = true
            this.alertMessage = response.data.message
          }
      })
      .catch(e => {
          this.errors.push(e)
      }) 
    },
 
    visitEditor() {
      this.editValuesMode = true
      // location.href = '/editor'
    },
    showDeleteEntryModal() {
      this.$bvModal.show('delete-entry-modal')
    },
    confirmDeleteEntry() {
      console.log('holy hell, they want to delete this entry..')
      console.log(this.selectedDay)
      console.log(this.selectedDay.id)
     
      axios.post(this.deleteEntryUrl, {id: this.selectedDay.id}, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(response => {
          console.log('wtf')
          console.log(response.data)
          console.log(response.data.status)
          console.log(response.data.status == 200)
          if (response.data.status == 200) {
            setTimeout(() => {  this.progressValue = 100; location.href = '/dashboard'; }, 250);
          } 
      })
      .catch(e => {
          this.errors.push(e)
      }) 

    }, 
    getRating(rating) {
      if (rating == 1){
          return "a bad day =/"
      } else if (rating == 2) {
          return "a MEH day.."
      } else if (rating == 3) {
          return "an OK day."
      } else if (rating == 4) {
          return "a good day!"
      } else if (rating == 5) {
          return "a GREAT day!"
      }
    },    
    setContents(contents) {
      this.noteText = contents
    },
    
    hideNoteEditor(){
      this.showNoteEditor = false
    },

    displayNoteEditor(){
      this.showNoteEditor = true
    }
  }  
}
</script>

<style lang="scss" scoped>
// @import '../assets/custom.scss'; doesnt work for some reason..


.activity-select {
  border: 1px solid #33cc99;
  border-radius: 5px;
  width: 180px;
  min-height:35px;
  float: left;
  font-size: 16px;
  background: white;
  color: black;
  font-size: 14px;
  transition-duration: 0.75s;

  &.is-selected {
    background: #33cc99 ;
    border: 1px solid #33cc99;
    color: white;
  }

}

.bad-activity-select {
  border: 1px solid #ff4816;
  border-radius: 5px;
  width: 180px;
  min-height:35px;
  float: left;
  font-size: 14px;
  background: white;
  color: black;
  transition-duration: 0.75s;

  &.is-selected {
    background: #ff4816 ;
    border: 1px solid #ff4816;
    color: white;
  }
}

#edit-button {
  float:right;
  height:60px;
  width:60px;
}

.score {
    right: 20px;
    height: 60px;
    width: 60px;
    border-radius: 15px;
    background: #FF9051;
    padding: 10px;
    font-size: 29px;
    text-align: center;
}


.link {
  font-size:14px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.delete {
  color: red;
  cursor: pointer;
}

#day-rating-text {
  font-family: 'Poppins', sans-serif;
  font-size:32px;
  // font-family: 'Exo', sans-serif;  // edifi fonts...
  // font-family: 'Montserrat Alternates', sans-serif;  // edifi fonts...
  // font-family: 'Nanum Myeongjo', serif;  // edifi fonts...
  // font-family: 'Outfit', sans-serif;  // edifi fonts...
  // font-family: 'Righteous', cursive;  // edifi fonts...
}

.day-note-text {
  // font-family: 'Poppins', sans-serif;
  font-family: 'Nanum Myeongjo', serif;  // edifi fonts...
  font-weight: 200;
}

.selectable {
  cursor:pointer;
}


</style>





//   <!-- option 2 -->
//   <!-- <div class="float-left" style="width: 250px;" v-for="bucket in buckets" v-bind:key="bucket.id">
//     <span class="float-left" style="font-size:16px;">{{ bucket.title }}</span>
//     <span v-for="act in bucket.activities" v-bind:key="act.id" @click="selectActivity(act)" class="p-2 m-1 activity-select float-left" :class="{ 'is-selected': selectedIds.includes(act.id) }">
//       <span class="mr-2">{{ act.title }}</span>
//       <b-badge variant="light">{{ act.value }}</b-badge>
//     </span>    
//   </div> -->

//   <!-- option 3 its altogether.. and its too messy =/-->
//   <!-- <span class="" style=" " v-for="bucket in buckets" v-bind:key="bucket.id">
//     <span v-for="act in bucket.activities" v-bind:key="act.id" @click="selectActivity(act)" class="p-2 m-1 activity-select-altogether" :class="{ 'is-selected': selectedIds.includes(act.id) }"  >
//       <span class="mr-2">{{ act.title }}</span>
//       <b-badge variant="light">{{ act.value }}</b-badge>
//       <span class="bucket" style="font-size:12px;">{{ bucket.title }}</span>
//     </span>    
//   </span>      -->
// <!-- </div> -->