<template>
  <div>
      <div v-if="!addBucketMode" class="my-2">
        <b-button @click="addBucket()" variant="light" class="my-2"><b-icon-plus /> Add another bucket</b-button>
        <b-badge v-if="showSuccessBadge" class="ml-2 my-2" pill variant="success">Success</b-badge>
      </div>
      <div v-else class="my-2">
        <b-button variant="light" class="d-inline-block mr-1" style="margin-top:-4px;height:40px;" @click="cancelAddNewBucket()"><b-icon-x font-scale="2" /></b-button>              
        <b-form-input class="mr-1" style="display:inline-block; width:220px;height:40px;" v-model="newBucketTitle" type="text" placeholder="bucket name"></b-form-input>
        <b-button variant="primary" class="mr-1 d-inline-block" style="margin-top:-4px;height:40px;" @click="addNewBucket()">Add bucket</b-button>
      </div>

      <b-spinner v-if="showSpinner" style="width: 3rem; height: 3rem;margin:50px;"  variant="primary" label="Spinning"></b-spinner>
      <div v-if="!showSpinner">
        <b-row class="my-3" no-gutters>
          <b-col v-for="(bucket, bucketIdx) in buckets" v-bind:key="bucket.id" cols="3" style="border: 1px solid lightgray; min-height:200px; padding:25px 15px;">
              <h3>{{ bucket.title }} 
                      
              <b-dropdown v-if="!bucket.is_default" size="lg" class="bucket-dropdown float-right" variant="link" toggle-class="text-decoration-none" no-caret right>
              <template #button-content>
                  <b-icon-three-dots-vertical @click="selectBucket(bucket)" style="font-size: 14px; cursor:pointer; color: gray;" font-scale="1"></b-icon-three-dots-vertical> 
              </template>
              <b-dropdown-item href="#" @click="showDeleteBucketModal()">Delete Bucket</b-dropdown-item>
              </b-dropdown>            
              
              </h3>
              <draggable v-model="bucket.activities" group="goodActs" @start="drag=true" @end="endDrag()">
                <div class="draggable p-2 mb-2" v-for="(element, i) in bucket.activities" :key="element.id">
                  <b-icon-arrows-move class="mr-1" font-scale="1"></b-icon-arrows-move>
                  <b-form-input class="mr-2" style="display:inline-block; width:140px; font-size:12px;" v-model="buckets[bucketIdx].activities[i].title" type="text" placeholder="Enter title"></b-form-input>
                  <b-form-input class="mr-2 text-center" style="display:inline-block; width:27px;padding:6px; font-size:12px;"  v-model="buckets[bucketIdx].activities[i].value" placeholder="0"></b-form-input>
                  <b-icon-trash class="" font-scale="1" style="cursor: pointer;" @click="showDeleteActivityModal(buckets[bucketIdx].activities[i])"></b-icon-trash>
                  <!-- <b-icon-trash class="" font-scale="1" style="cursor: pointer;" @click="removeActivity(buckets[bucketIdx].activities[i].id)"></b-icon-trash> -->
                  
                </div>

                <div class="note-draggable py-2 my-2" style="height:30px;">
                  <b-button v-if="newActivityBucketId != bucket.id" @click="newActivityBucketId = bucket.id" variant="light" class="w-100"><b-icon-plus /> Add activity</b-button>
                  <!-- <b-button v-if="newActivityBucketId == bucket.id" variant="light" class="d-inline-block mr-1" style="margin-top:-4px;height:25px;width:25px;" @click="newActivityBucketId = 0"><b-icon-x font-scale="2" /></b-button>               -->
                  <div v-if="newActivityBucketId == bucket.id" variant="light" class="d-inline-block mr-1" style="width:10%;cursor:pointer" @click="newActivityBucketId = 0"><b-icon-x font-scale="2" /></div>              
                  <b-form-input v-if="newActivityBucketId == bucket.id" class="mr-1" style="width:55%; display:inline-block; " v-model="newActivityTitle" type="text" placeholder="enter activity"></b-form-input>
                  <b-form-input class="mr-2 text-center" v-if="newActivityBucketId == bucket.id"  style="width:10%;display:inline-block;padding:6px; font-size:12px;"  v-model="newActivityValue" placeholder="0"></b-form-input>
                  <b-button v-if="newActivityBucketId == bucket.id" variant="primary" class="mr-1 d-inline-block" style="width:15%;" @click="saveNewActivity(bucket.id)"><b-icon-check2 /></b-button>              
                </div>

              </draggable>
          </b-col>      
        </b-row>
      </div>

      <b-modal
        size="md"
        id="delete-bucket-modal"
        title="Delete entry"
      >
        <p class="my-4">Are you sure you want to delete this bucket? there's no going back...</p>
        <template #modal-footer="{ cancel, hide }">
          <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
            Cancel
          </b-button>

          <b-button size="sm" variant="danger" @click="confirmDeleteBucket()">
            Delete
          </b-button>
        </template>
      </b-modal>


      <b-modal
        size="md"
        id="delete-activity-modal"
        title="Delete entry"
      >
        <p class="my-4">Are you sure you want to delete this activity? there's no going back...</p>
        <template #modal-footer="{ cancel, hide }">
          <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
            Cancel
          </b-button>

          <b-button size="sm" variant="danger" @click="confirmDeleteActivity()">
            Delete
          </b-button>
        </template>
      </b-modal>


  </div>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies'
import draggable from 'vuedraggable'

export default { 
  name: 'AppEditor',
  components: {
    draggable,
  },
  data() {
    return {
      csrfToken: '',
      showSpinner: true,

      buckets: [],
      selectedBucket: {},
      addBucketMode: false,
      newBucketTitle: '',
      showSuccessBadge: false,
      createBucketUrl: `${process.env.VUE_APP_HOST}/api/create_bucket/`,
      saveBucketsUrl: `${process.env.VUE_APP_HOST}/api/save_buckets/`,
      deleteBucketUrl: `${process.env.VUE_APP_HOST}/api/delete_bucket/`,

      drag: false,

      newActivityTitle: '',
      newActivityValue: 0,
      newActivityBucketId: 0,
      createActivityUrl: `${process.env.VUE_APP_HOST}/api/create_default_activity/`,

      activityToDelete: {},
      deleteActivityUrl: `${process.env.VUE_APP_HOST}/api/delete_default_activity/`,
    }
  },
  created() {
    this.csrfToken = VueCookies.get('csrftoken')
    this.loadBuckets()
  },

  methods: {
    loadBuckets () {
      const postHeaders = { "content-type": "application/json", 'X-CSRFToken': this.csrfToken }
      const url = `${process.env.VUE_APP_HOST}/api/get-current-info/`
      console.log(`navbar url calling ${url}`)

      axios.get(url, postHeaders)
      .then(response => {
        setTimeout(() => {  this.showSpinner = false }, 750);
        console.log('buckets')
        this.buckets = response.data.activity_buckets
        console.log(this.buckets)

        this.buckets.forEach((bucket) => {
          bucket.activities.forEach((activity) => {
            if (activity.value > 0) {
              // this.positiveActivities.push(activity)
            } else if (activity.value < 0) {
              // this.badActivities.push(activity)
            }
          })
        })
      })

    },
    addPeople() {
      console.log('addpeople..')
    },
    toggleMode() {
      if (this.editValuesMode) {
        this.editValuesMode = false
      } else {
        this.editValuesMode = true
      }
    },

    // save buckets and activities altogether..
    saveBuckets() {
      console.log('saving buckets') 
      console.log(this.buckets)
      const payload = {
          buckets: this.buckets,
      }

      axios.post(this.saveBucketsUrl, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(response => {
          // this.$store.commit('setActiveUser', response.data)
          console.log(response.data)
          // location.href = "/editor"
      })
      .catch(e => {
          this.errors.push(e)
      }) 

    },
    addBucket () {
      this.addBucketMode = true
    },
    endDrag () {
      this.drag = false
      this.saveBuckets()
    },

    // add / cancel new bucket
    cancelAddNewBucket() {
      this.addBucketMode = false
    },
    addNewBucket() {
      this.addBucketMode = false
      const payload = {
        title: this.newBucketTitle,
      }
      axios.post(this.createBucketUrl, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(() => {
          // this.$store.commit('setActiveUser', response.data)
          // console.log(response.data)
          this.newBucketTitle = ''
          this.addBucketMode = false
          this.showSuccessBadge = true
          setTimeout(() => {  this.showSuccessBadge = false }, 1000);          
          this.loadBuckets()
      })
      .catch(e => {
          this.errors.push(e)
      }) 
    },

    // select bucket - show dropdown
    selectBucket(bucket) {
      this.selectedBucket = bucket
    },

    // delete buckets
    showDeleteBucketModal () {
      this.$bvModal.show('delete-bucket-modal')
    },

    confirmDeleteBucket(){
      axios.post(this.deleteBucketUrl, this.selectedBucket, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(() => {
        console.log('response..')
          // location.href = "/editor"
          // this.newBucketTitle = ''
          // this.addBucketMode = false
          this.loadBuckets()
          this.$bvModal.hide('delete-bucket-modal')
    
      })
      .catch(e => {
          this.errors.push(e)
      }) 
    },

    // idk
    dashboardLink() {
      location.href = "/dashboard"
    },


    // save new activities
    addActivityToBucket(bucketId) {
      console.log('setting bucketid..')
      console.log(bucketId)
      this.newActivityBucketId = bucketId
      // this.show(bucketId)
    },
    saveNewActivity(bucketId){
      const payload = {
        activity: {
          title: this.newActivityTitle,
          value: this.newActivityValue,
          bucket_id: bucketId,
        }
      }
      console.log(payload)


      axios.post(this.createActivityUrl, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(() => {
        // this.$store.commit('setActiveUser', response.data)
          // location.href = "/editor"
          this.newActivityTitle = ''
          this.newActivityValue = 0
          this.newActivityBucketId = 0
          // this.showSuccessBadge = true
          // setTimeout(() => {  this.showSuccessBadge = false }, 1000);          
          this.loadBuckets()
      })
      .catch(e => {
          this.errors.push(e)
      })       

    },

    // delete activities
    showDeleteActivityModal (activity) {
      this.activityToDelete = activity
      this.$bvModal.show('delete-activity-modal')
    },
    confirmDeleteActivity() {
      axios.post(this.deleteActivityUrl, this.activityToDelete, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(() => {
        // console.log(response)
        // location.href = "/editor"
        // this.newBucketTitle = ''
        // this.addBucketMode = false
        this.loadBuckets()
        this.$bvModal.hide('delete-activity-modal').hide()
      })
      .catch(e => {
          this.errors.push(e)
      }) 


    },


  }
}
</script>

<style lang="scss" scoped>

.draggable {
  border: 1px solid lightgray;
  font-size:13px;
  border-radius:3px;
  width: 100%;
  cursor: pointer;
}

.not-draggable {
  border: 1px solid lightgray;
  font-size:13px;
  border-radius:3px;
  width: 100%;
}

.link {
  font-size:14px;
  text-decoration: underline;
  color: #33cc99;
  cursor: pointer;
}

.bucket-dropdown {
  position: absolute;
  right: 0;
  top: 14px;
  color: black;  
}


</style>
