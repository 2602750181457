<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="/dashboard">Feather</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/dashboard">Dashboard</b-nav-item>
          <b-nav-item href="/calendar">Calendar</b-nav-item>
          <b-nav-item v-b-modal.record-activity-modal>New</b-nav-item>  
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form> -->

          <b-nav-item href="/stats">Stats</b-nav-item>
          <b-nav-item href="/editor">Edit Activities</b-nav-item>
          <!-- <b-nav-item-dropdown>
            <b-dropdown-item href="/editor">Edit Activities</b-dropdown-item>
            <b-dropdown-item v-if="authenticated" href="/api-auth/logout/?next=/">Log out</b-dropdown-item>
          </b-nav-item-dropdown> -->

          <b-nav-item-dropdown v-if="currentUser.user" text="Account" right>
            <b-dropdown-item href="/settings">Settings</b-dropdown-item>
            <b-dropdown-item v-if="authenticated" href="/api-auth/logout/?next=/">Log out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>

    <!-- <profile-wizard @finish-wizard="finishWizard()" :show="showWizard" /> -->

  </div>
</template>


<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'
// import izard from './simplifi/ProfileWizard.vue'
// import RecordActivitiesForm from './RecordActivitiesForm.vue'

export default {
  name: 'Navbar',
  data() {
    return {
      email: '',
      authenticated: false,
      notifications: [],
      showWizard: false,
    }
  },
  components: {
    // ProfileWizard,
    // RecordActivitiesForm,
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }, 
    savedContentsCount() {
      return this.$store.state.savedContents.length
    }, 
  },  
  created() {
    console.log(`envs ${process.env}`)

    const csrfToken = VueCookies.get('csrftoken')
    const postHeaders = { "content-type": "application/json", 'X-CSRFToken': csrfToken }
    const url = `${process.env.VUE_APP_HOST}/api/get-current-info/`
    console.log(`navbar url calling ${url}`)

    axios.get(url, postHeaders)
    .then(response => {
      // console.log("get-current-info navbar setting current user in store..");
      this.$store.commit('setActiveUser', response.data)
      this.email = response.data.username
      this.authenticated = true
      this.notifications = response.data.notifications

      // this.showWizard = true
      // this.$emit('wizard-in-progress')
      // if (response.data.profile) {
      //   if (response.data.profile.age_bracket === 0 || response.data.profile.income_bracket === 0) {
      //     this.showWizard = true
      //     this.$emit('wizard-in-progress')
      //     // this.$bvModal.show('modal-financial-profile')  // not working
      //   }
      // }

    })
    .catch(e => {
      console.log(e)
      this.email = 'unauthenticated'
      this.authenticated = false
      this.$store.commit('setActiveUser', {})
    })
  },
  methods: {
    finishWizard () {
      // FYI theres a bit of a chained-event sequence with these emits
      // ProfileWizard -> Navbar -> AppDashboard      
      this.showWizard = false
      this.$emit('fetch-dashboard-data')
    }
  }  
}
</script>

<style lang="scss" scoped>

</style>


